import httpUtil from "@/utils/httpUtil";

export const selectAgentCompanyList = params => httpUtil.post("/api/crmPc/companyGoods/selectAgentCompanyList", params);


export const getAdminCompanyInfo = params => httpUtil.post("/api/crmPc/companyGoods/getAdminCompanyInfo", params);


/** 获取待结算列表 */
export const getAgent = params => httpUtil.post("/api/crmPc/agent/getAgent", params);
// export function getAgent(params,type) {
// 	return httpUtil.post("/api/crmPc/agent/getAgent", params, type)
// }

/** 发起结算 */

export function addAgentSettlement(params,type) {
	return httpUtil.post("/api/crmPc/agent/addAgentSettlement", params, type)
}